/**
 ** This file is for storing Tanstack Query
 */
export const CourseKey = {
  ResultsList: 'courseResultList',
  SectionList: 'courseSectionList',
  SubsectionList: 'courseSubsectionList',
  UnitList: 'courseUnitList',
  ActivityList: 'courseActivityList',
}

export const ClassKey = {
  ExamList: 'classExamList',
  ExamInfo: 'examInfo',
}

export const UserKey = {
  ExamList: 'userExamList',
}
